<template>
  <div class="order-confirmed">
    
    <PackVideo v-if="showVideo" :src="video" @onVideoEnd="onVideoEnd" />
    
    <template v-if="showContent">
      <ImageResize :src="image" alt="frac_pack" type="frac_pack" />

      <div class="flex justify-between items-center w-full mt-s40">
        <h1 class="headline-medium">{{ $t('trade.order_confirmed') }}</h1>
        <CheckCircle data-testid="check-circle-icon" class="check-circle-icon" />
      </div>

      <div class="flex justify-between items-center w-full mt-s20 mb-s48">
        <p class="body-text-large">{{ $t('rewards.remaining_gems') }}:</p>
        <p class="subheadline-small">{{ numberFormat(balance, 0) }}</p>
      </div>

      <div class="w-full">
        <ButtonV2
          v-if="isFracPack"
          @onClick="openFracPack"
          :label="$tc('frac_pack.open_frac_pack')"
          testId="btn-openFracPack"
          :loading="isLoading"
          size="medium"
          wide
        />

        <ButtonV2
          @onClick="saveFracPackForLater"
          class="mt-s12"
          :label="saveForLaterLabel"
          :version="saveForLaterVersion"
          testId="btn-saveForLater"
          size="medium"
          wide
        />
      </div>
    </template>
  </div>
</template>

<script>
import { ButtonV2, ImageResize } from '@/components/misc';
import { PackVideo } from '@/modules/pack';
import CheckCircle from '@/assets/icons/check_circle.svg';

export default {
  name: 'RewardOrderConfirmedLayout',
  components: {
    ButtonV2,
    CheckCircle,
    PackVideo,
    ImageResize,
  },
  props: {
    balance: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#FB4DAD',
    },
    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png',
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    video: String,
    showContent: {
      type: Boolean,
      default: true,
    },
    showVideo: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    onVideoEnd() {
      this.$emit('onVideoEnd');
    },
    openFracPack() {
      this.$emit('onOpenFracPack');
    },
    saveFracPackForLater() {
      this.$emit('onSaveFracPackForLater');
    },
  },
  computed: {
    isFracPack() {
      return this.type === 'fracpack';
    },

    saveForLaterLabel() {
      return this.isFracPack ? this.$t('frac_pack.save_frac_pack') : this.$t('navigation.close');
    },

    saveForLaterVersion() {
      return this.isFracPack ? 'secondary' : 'primary';
    },
  },
};
</script>

<style scoped>
.order-confirmed {
  @apply flex flex-col justify-center items-center;
  min-width: 335px;
}

.check-circle-icon {
  @apply h-s40 w-s40 text-text-active-2 fill-current;
}

svg.check-circle-icon path {
  stroke: var(--colors-background-page);
}
</style>
