<template>
  <RewardOrderConfirmedLayout
    :balance="balance"
    :color="color"
    :image="image"
    :isLoading="isLoading"
    :type="type"
    :video="openedPackVideo"
    :showContent="showContent"
    :showVideo="showVideo"
    @onOpenFracPack="handleOpenFracPack"
    @onVideoEnd="showOpenPack"
    @onSaveFracPackForLater="hideModal"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RewardOrderConfirmedLayout from './layout';
import PackState from '@/enums/PackState';

export default {
  name: 'RewardOrderConfirmed',
  
  components: {
    RewardOrderConfirmedLayout,
  },
  
  props: {
    id: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#FB4DAD',
    },
    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png',
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      showContent: true,
      showVideo: false,
      packOpenProps: {
        noPadding: true,
        isClosable: false,
      }
    };
  },

  computed: {
    ...mapState('rewards', ['balance']),
    ...mapState('ui', ['currentTheme']),
    ...mapState('pack', [
      'packFinalize', 
      'openedPackVideo',
      'bypassPackVideo',
    ]),
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),
    ...mapActions('pack', ['openPack']),

    async handleOpenFracPack() {
      try {
        this.isLoading = true;
        this.openPack({
          id: this.id, 
          state: PackState.PENDING, 
        });
        this.isLoading = false;
      } catch (err) {
        await this.showError(err);
        this.hideModal();
      }
    },

    showOpenPack() {
      return this.showModal('PackOpen', {
        ...this.packOpenProps, 
        fracPack: this.packFinalize
      });
    },
  },

  watch: {
    openedPackVideo(src) {
      if(src) {
        this.setModalProps({ ...this.packOpenProps });
        this.showContent = false;
        this.showVideo = true;
      }
    },
    packFinalize(pack) {
      if(pack && this.bypassPackVideo) {
        return this.showOpenPack();
      }
    },
  }
};
</script>

<style scoped>
</style>
